<template>
  <div id="app">
    <logo/>
  </div>
</template>

<script>
import logo from './components/logo.vue'

export default {
  name: 'App',
  components: {
    logo
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100vh;
  background: url(./assets/img/bg.png) no-repeat;
  background-size: 100% 100%;
}
</style>
