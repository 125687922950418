<template>
  <div class="login">
    <div class="login-text" style="padding-top: 100px;">你好，</div>
    <div class="login-text login-text1">欢迎使用AI智能写作助手</div>
    <div class="login-box">
      <div class="login-box1">
        <img src="../assets/img/phone.png" alt="">
        <!-- <el-input class="transparent-bg" v-model="phone" type="number" :maxlength="11" placeholder="请输入手机号"></el-input> -->
        <input class="transparent-bg" v-model="phone" maxlength="11" type="number" placeholder="请输入手机号">
      </div>
      <div class="login-box1 login-box2">
        <img src="../assets/img/password.png" alt="">
        <input class="transparent-bg" v-model="code" type="number" placeholder="请输入验证码">
        <!-- <el-button style="background-color: none;">发送验证码</el-button> -->
        <button id="button" :disabled="i" :class="i ? 'code disable' : 'code enable'">发送验证码{{ (i != null) ? `(${i})` :
          '' }}</button>
      </div>
      <div id="captcha-element"></div>
      <div class="login-btn" @click="login">
        立即登录
      </div>
    </div>


    <div class="login-text3">登录代表你已经同意 <span @click="yhxx">《用户协议》</span> 和 <span @click="ysxx">《隐私权政策》</span> </div>

    <div class="login-other">
      <div class="other-title">第三方授权登录</div>
      <div class="other-logo">
        <div class="other-logo1" @click="wxLogin">
          <img src="../assets/img/wx.png" alt="">
          微信登录
        </div>
        <div class="other-logo1" @click="phoneLogin">
          <img src="../assets/img/phone1.png" alt="">
          本机号码登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'
export default {
  data() {
    return {
      phone: '',
      captcha: null,
      interval: null,
      i: null,
      code: null,
      header: {}
    }
  },
  created: function () {
    if (window.android) {
      const header = window.android.getLoginHeaders()
      console.log(header)
      this.header = JSON.parse(header)
    }
    // 弹出式
    initAliyunCaptcha({
      SceneId: '1xbwlboq', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: '19t15o', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: '#button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 300,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      region: 'cn' //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
    });

    // 绑定验证码实例函数。该函数为固定写法，无需修改
    function getInstance(instance) {
      this.captcha = instance;
    }

    const that = this
    async function captchaVerifyCallback(captchaVerifyParam) {
      const verifyResult = {}
      const phone = that.phone
      console.log(phone)
      if (!that.validPhone(phone)) {
        // that.$message.error('请输入正确的手机号')
        window.alert('请输入正确的手机号')
        verifyResult.captchaResult = true
      } else {
        const params = {
          phone: phone,
          verifyParam: captchaVerifyParam
        }
        const res = await http.post('/draw/app/sms/v2', params,that.header)
        if (200 == res.code) {
          if (res.data || res.data == null) {
            that.i = 60
            that.interval = setInterval(() => {
              that.i -= 1
              if (that.i == 0) {
                clearInterval(that.interval)
                that.interval = null
                that.i = null
              }
            }, 1000)
            verifyResult.captchaResult = true
          } else {
            verifyResult.captchaResult = false
          }
        } else {
          // that.$message.error(res.msg)
          window.alert(res.msg)
          verifyResult.captchaResult = true
        }
      }
      return verifyResult;
    }

    // 业务请求验证结果回调函数
    function onBizResultCallback(bizResult) {
      console.log(bizResult)
    }
  },
  methods: {
    yhxx: function () {
      if (window.android) {
        window.android.switchUserAgreement()
      }
    },
    ysxx: function () {
      if (window.android) {
        window.android.switchPrivacyAgreement()
      }
    },
    phoneLogin: function () {
      if (window.android) {
        window.android.switchNativeLogin()
      }
    },
    wxLogin: function () {
      if (window.android) {
        window.android.switchWxLogin()()
      }
    },
    login: async function () {
      if (!this.phone) {
        // this.$message.error('请输入正确的手机号')
        window.alert('请输入正确的手机号')
        return
      }
      if (!this.code) {
        // this.$message.error('请输入验证码')
        window.alert('请输入验证码')
      }
      const params = {
        phone: this.phone,
        code: this.code
      }
      const res = await http.post('/auth/app/login/sms', params,this.header)
      if (200 == res.code) {
        if (window.android) {
          window.android.loginSuccess(res.data.access_token)
        }
      } else {
        alert(res.msg)
      }
    },
    validPhone: function (phone) {
      if (phone == null || phone == '') {
        return false
      }
      const regex = /^1\d{10}$/
      return regex.test(phone)
    }
  }
}
</script>

<style scoped>
.login-text {
  width: 44rem;
  margin: 0 auto;
  font-weight: normal;
  font-size: 4rem;
  color: #222222;
}

.login-text1 {
  font-size: 3rem;
}

.login-box {
  width: 44rem;
  margin: 0 auto;
  padding: 1rem 0;
  margin-top: 1rem;
  border-radius: 3rem 3rem 0 0;
  background-color:  rgba(255, 255, 255, 0.21);
}

.login-box1 {
  width: 38rem;
  height: 6rem;
  border-bottom: 1px solid #C5D4E4;
  margin: 0 3rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.login-box1 img {
  width: 2.3rem;
  height: 3rem;
  margin-right: 1rem;
}

.login-box2 img {
  width: 2.5rem;
}


.login-box1 input.transparent-bg {
  width: 23rem;
  background-color: transparent;
  border: none;
  /* 可选，移除边框 */
  outline: none;
  /* 可选，移除焦点时的轮廓 */
  font-size: 2rem;
  color: #000000;
}

.transparent-bg::placeholder {
  color: #999999;
}

.code {
  background: none;
  border: none;
}

.enable {
  color: #156BF4;
}

.disable {
  color: #696969;
}

.login-btn {
  width: 38rem;
  height: 6rem;
  line-height: 6rem;
  text-align: center;
  border-radius: 3rem;
  background: linear-gradient(48deg, #1689FF, #1452EB);
  font-size: 2rem;
  color: #FFFFFF;
  margin: 0 auto;
  margin-top: 3rem;
}

.login-text3 {
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: #666666;
  margin-top: 2rem;
}

.login-text3 span {
  color: #F34242;
}

.login-other {
  /* position: fixed; */
  margin-top: 12rem;
}

.other-title {
  width: 50rem;
  text-align: center;
  font-size: 1.6rem;
  color: #999999;
  font-weight: 400;
  margin-bottom: 3rem;
}

.other-logo {
  padding: 0 13rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  color: #666666;
  font-weight: 400;
}

.other-logo1 {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.other-logo1 img {
  width: 7.3rem;
  height: 7.3rem;
  margin-bottom: 1rem;
}
</style>
